<template>
  <div class="shadow p-2 mb-3">
    <span><b>画像を選択</b></span><br>
    <input
        type="file"
        name="uploader"
        ref="uploader"
        @change="onChangeFile"
    >
    <loader v-if="loadingLocal"/>
<!--    <button-->
<!--        type="submit"-->
<!--        class="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"-->
<!--        v-on:click="test">-->
<!--      <div-->
<!--      >確認</div></button>-->
<!--    <file-pond-->
<!--        name="file"-->
<!--        ref="pond"-->
<!--        class-name="my-pond"-->
<!--        label-idle="Drop files here..."-->
<!--        allow-multiple="false"-->
<!--        allowRevert="false"-->
<!--        v-bind:server="filepondServer"-->
<!--        v-on:processfile="onfileuploaded"-->
<!--        v-on:error="onfileuploaderror"-->
<!--    />-->
    <div
        class="bg-rose-300"
        v-if="this.image_url"
    >
      <img
          class="object-contain h-48 w-full"
          v-bind:src="getFullUrl(this.image_url)">
    </div>
  </div>
</template>

<script>
// import vueFilePond from 'vue-filepond'
// import 'filepond/dist/filepond.min.css'
// const FilePond = vueFilePond()
import axios from 'axios'
// import Base from '@/mixin/Base.js'

export default {
  // mixins: [Base],
  namespaced: true,
  data(){
    return{
      image_url: "",
      file: null,
      loadingLocal: false,
      state: null
    }
  },
  components: {
    // FilePond,
  },
  computed: {
    filepondServer: function () {
      return {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const formData = new FormData()
          formData.append(fieldName, file, file.name)
          const request = new XMLHttpRequest()
          request.timeout = 60 * 1000

          // request.setRequestHeader('ContentType', 'multipart/form-data')
          request.open('POST', `${process.env.VUE_APP_ENDPOINT}/ai/`)
          // request.setRequestHeader('Authorization', `Token ${this.token}`)
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }

          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load(request.responseText)
              this.image_url = "hoge"
              console.log(this.image_url)
            } else {
              // Can call the error method if something is wrong, should exit after
              error('oh no')
            }
          }
          request.send(formData)
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              request.abort()

              // Let FilePond know the request has been cancelled
              abort()
            }
          }
        },
        fetch: null,
        load: null
      }
    }
  },
  methods: {
    async onChangeFile(e){
      const { target } = e;
      if (!(target instanceof HTMLInputElement )) return;
      if (target.files == null) return;
      // console.log(target.files[0])
      this.file = target.files[0]

      this.loadingLocal = true
      // const xhr = new XMLHttpRequest()
      const formData = new FormData()
      formData.append("file", this.file)
      axios
          .post(`${process.env.VUE_APP_ENDPOINT}/ai/`, formData)
          .then(function(response) {
            // response 処理
            this.image_url = response.data["image_url"]
            this.loadingLocal = false
          }.bind(this))  //Promise処理を行う場合は.bind(this)が必要
          .catch(function(error){  //バックエンドからエラーが返却された場合に行う処理について
            this.state=error.data
            alert("Error")
          }.bind(this))
          .finally(function(){

            target.value = ""
          }.bind(this))
      // xhr.open("POST", `${process.env.VUE_APP_ENDPOINT}/ai/`)
      // xhr.overrideMimeType('text/plain; charset=x-user-defined-binary')
      // // xhr.setRequestHeader('Authorization', `Token ${this.token}`)
      // xhr.onload = function () {
      //   this.loadingLocal = false
      //   if (xhr.status >= 200 && xhr.status < 300) {
      //     // console.log(xhr.responseText)
      //
      //     const data = JSON.parse(xhr.responseText)
      //     self.image_url = data["image_url"]
      //     // this.onfileuploaded()
      //     // alert('登録完了!')
      //
      //   } else {
      //     alert('登録失敗!')
      //   }
      // }
      // xhr.send(formData);

    },
    getFullUrl: function (url) {
      if (!url) return ''
      const base = process.env.VUE_APP_SERVERHOSTNAME
      if (!url.includes('https://')) {
        console.log(`${base}${url.replace(base, '')}`)
        return `${base}${url.replace(base, '')}`
      } else {
        console.log(url)
        return url
      }
    },
  },
}
</script>
